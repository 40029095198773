import { lazy } from "react";

export const Login = lazy(() => import("./Login"));
export const ForgetPassword = lazy(() => import("./ForgetPassword"));

// SuperAdmin
export const SuperAdminDashboard = lazy(() => import("./SuperAdminDashboard"));
export const SuperAdminAddStoreAdmin = lazy(() =>
  import("./SuperAdminAddStoreAdmin")
);
export const SuperAdminViewStoreAdmin = lazy(() =>
  import("./SuperAdminViewStoreAdmin")
);

export const SuperAdminInventory = lazy(() => import("./SuperAdminInventory"));
export const SuperAdminViewEvaluatedCar = lazy(() =>
  import("./SuperAdminViewEvaluatedCar")
);
export const SuperAdminViewEvaluatedCarList = lazy(() =>
  import("./SuperAdminViewEvaluatedCarList")
);
export const SuperAdminViewEnquiry = lazy(() =>
  import("./SuperAdminViewEnquiry")
);
export const SuperAdminViewEnquiryList = lazy(() =>
  import("./SuperAdminViewEnquiryList")
);
export const SuperAdminViewFeedback = lazy(() =>
  import("./SuperAdminViewFeedback")
);

export const SuperAdminViewSupport = lazy(() =>
  import("./SuperAdminViewSupport")
);
export const SuperAdminNotification = lazy(() =>
  import("./SuperAdminNotification")
);
export const SuperAdminInventoryList = lazy(() =>
  import("./SuperAdminInventoryList")
);
export const SuperAdminMarketPlaceEnquiry = lazy(() =>
  import("./SuperAdminMarketPlaceEnquiry")
);
export const SuperAdminBiddingCars = lazy(() =>
  import("./SuperAdminBiddingCars")
);
export const SuperAdminCompliedBiddingCars = lazy(() =>
  import("./SuperAdminCompliedBiddingCars")
);

// Admin
export const AdminDashboard = lazy(() => import("./AdminDashboard"));

export const AdminEvaluatedCars = lazy(() => import("./AdminEvaluatedCars"));
export const AdminViewEnquiry = lazy(() => import("./AdminViewEnquiry"));
export const AdminEvaluatedCarsList = lazy(() =>
  import("./AdminEvaluatedCarsList")
);
export const AdminViewEnquiryList = lazy(() =>
  import("./AdminViewEnquiryList")
);

export const AdminAddStoreManager = lazy(() =>
  import("./AdminAddStoreManager")
);
export const AdminViewStoreManager = lazy(() =>
  import("./AdminViewStoreManager")
);
export const AdminCarDetails = lazy(() => import("./AdminCarDetails"));
export const AdminInventory = lazy(() => import("./AdminInventory"));
export const AdminStoreDetails = lazy(() => import("./AdminStoreDetails"));
export const AdminEditStoreDetails = lazy(() =>
  import("./AdminEditStoreDetails")
);
export const AdminViewSupport = lazy(() => import("./AdminViewSupport"));
export const AdminNotication = lazy(() => import("./AdminNotication"));

export const AdminViewFeedback = lazy(() => import("./AdminViewFeedback"));
export const AdminInventoryList = lazy(() => import("./AdminInventoryList"));

// StoreManager
export const StoreManagerDashboard = lazy(() =>
  import("./StoreManagerDashboard")
);
export const StoreManagerAddStoreWorker = lazy(() =>
  import("./StoreManagerAddStoreWorker")
);
export const StoreManagerViewStoreWorker = lazy(() =>
  import("./StoreManagerViewStoreWorker")
);
export const StoreManagerProfile = lazy(() => import("./StoreManagerProfile"));
export const StoreManagerEditProfile = lazy(() =>
  import("./StoreManagerEditProfile")
);
export const StoreManagerBiddingCars = lazy(() =>
  import("./StoreManagerBiddingCars")
);
export const StoreManagerEvaluatedCars = lazy(() =>
  import("./StoreManagerEvaluatedCars")
);
export const StoreManagerInventry = lazy(() =>
  import("./StoreManagerInventry")
);
export const StoreManagerViewSupport = lazy(() =>
  import("./StoreManagerViewSupport")
);
export const StoreManagerNotification = lazy(() =>
  import("./StoreManagerNotification")
);
export const StoreManagerViewFeedback = lazy(() =>
  import("./StoreManagerViewFeedback")
);
export const StoreManagerViewNewBuyer = lazy(() =>
  import("./StoreManagerViewNewBuyer")
);

// StoreWorker
export const StoreWorkerDashboard = lazy(() =>
  import("./StoreWorkerDashboard")
);
export const StoreWorkerAddEvaluateCars = lazy(() =>
  import("./StoreWorkerAddEvaluateCars")
);
export const StoreWorkerViewEvaluatedCars = lazy(() =>
  import("./StoreWorkerViewEvaluatedCars")
);
export const StoreWorkerProfile = lazy(() => import("./StoreWorkerProfile"));
export const StoreWorkerEditProfile = lazy(() =>
  import("./StoreWorkerEditProfile")
);
export const StoreWorkerViewInventory = lazy(() =>
  import("./StoreWorkerViewInventory")
);
export const StoreWorkerNewBuyer = lazy(() => import("./StoreWorkerNewBuyer"));
export const StoreWorkerViewNewBuyer = lazy(() =>
  import("./StoreWorkerViewNewBuyer")
);

// Common Layout
export const Notification = lazy(() => import("./Notification"));
export const BookedCar = lazy(() => import("./BookedCar"));
export const SoldCars = lazy(() => import("./SoldCars"));

export const AllUsers = lazy(() => import("./AllUsers"));
export const Support = lazy(() => import("./Support"));

export const Feedback = lazy(() => import("./Feedback"));
export const NotFound = lazy(() => import("./NotFound"));

export const ChangePassword = lazy(() => import("./ChangePassword"));
export const Marketplace = lazy(() => import("./Marketplace"));
