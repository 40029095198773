import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
const IsLoadComponent = () => {
  console.log(window?.location?.host);
  //maintenance mode for selected routes
  // if (window?.location?.host === "carkarobar-store.web.app")
  //   return <h1>site under maintenance</h1>;
  return <App />;
};
ReactDOM.render(<IsLoadComponent />, document.getElementById("root"));
