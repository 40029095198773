import AdminAddStoreManager from "Pages/AdminAddStoreManager";
import { Route, Switch } from "react-router-dom";
import {
  Marketplace,
  AdminCarDetails,
  AdminDashboard,
  AdminEditStoreDetails,
  AdminEvaluatedCars,
  AdminEvaluatedCarsList,
  AdminInventory,
  AdminInventoryList,
  AdminNotication,
  AdminStoreDetails,
  AdminViewEnquiry,
  AdminViewEnquiryList,
  AdminViewFeedback,
  AdminViewStoreManager,
  AdminViewSupport,
  AllUsers,
  ChangePassword,
  Feedback,
  Support,
} from "../Pages";

const AdminRoutes = () => {
  return (
    <Switch>
      <Route path="/" component={AdminDashboard} exact />

      <Route path="/admin-dashboard" component={AdminDashboard} exact />
      <Route path="/add-store-manager" component={AdminAddStoreManager} exact />
      <Route
        path="/view-store-manager"
        component={AdminViewStoreManager}
        exact
      />
      <Route path="/AdminCarDetails" component={AdminCarDetails} exact />
      <Route
        path="/admin-view-evaluated-car"
        component={AdminEvaluatedCars}
        exact
      />
      <Route path="/admin-view-enquiry" component={AdminViewEnquiry} exact />
      <Route path="/admin-inventory" component={AdminInventory} exact />
      <Route path="/AdminStoreDetails" component={AdminStoreDetails} exact />
      <Route
        path="/AdminEditStoreDetails"
        component={AdminEditStoreDetails}
        exact
      />
      <Route path="/support" component={Support} exact />
      <Route path="/view-support" component={AdminViewSupport} exact />
      <Route path="/Notification" component={AdminNotication} exact />
      <Route path="/all-users" component={AllUsers} exact />
      <Route path="/change-password" component={ChangePassword} exact />
      <Route path="/feedback" component={Feedback} exact />
      <Route path="/admin-view-feedback" component={AdminViewFeedback} exact />

      <Route path="/AdminInventory/:id" exact component={AdminInventoryList} />
      <Route
        path="/AdminViewEvaluatedCar/:id"
        exact
        component={AdminEvaluatedCarsList}
      />
      <Route
        path="/AdminViewEnquiry/:id"
        exact
        component={AdminViewEnquiryList}
      />
      <Route path="/Marketplace" component={Marketplace} exact />
    </Switch>
  );
};

export default AdminRoutes;
