import { Loading } from "components/core";
import { useAuthContext } from "Config";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  AdminRoutes,
  PublicRoutes,
  StoreManagerRoutes,
  StoreWorkerRoutes,
  SuperAdminRoutes,
} from "Routes";

const Router = () => {
  const { user, isLoaded } = useAuthContext();

  const renderByRole = (role) => {
    switch (role) {
      case "SUPERADMIN":
        return <SuperAdminRoutes />;
      case "ADMIN":
        return <AdminRoutes />;
      case "STOREMANAGER":
        return <StoreManagerRoutes />;
      case "STOREWORKER":
        return <StoreWorkerRoutes />;
      default:
        return <PublicRoutes />;
    }
  };
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        {isLoaded ? renderByRole(user?.role) : <Loading />}
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
