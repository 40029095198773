import { CircularProgress, Container } from "@material-ui/core";

const Loading = () => {
  return (
    <Container>
      <CircularProgress className="circularProgress" />
    </Container>
  );
};

export default Loading;
