import { Route, Switch } from "react-router-dom";
import { NotFound, Marketplace } from "../Pages";
import ForgetPassword from "../Pages/ForgetPassword";
import Login from "../Pages/Login";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/ForgetPassword" component={ForgetPassword} exact />
      <Route path="/Marketplace" component={Marketplace} exact />
      <Route path="*" component={NotFound} exact />
    </Switch>
  );
};

export default PublicRoutes;
