import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab/";
import { LockOutlined } from "@material-ui/icons/";
import { auth } from "Config";

const useStyles = makeStyles((theme) => ({
  // paper: {
  //   marginTop: theme.spacing(8),
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  // },
  // avatar: {
  //   margin: theme.spacing(1),
  //   backgroundColor: theme.palette.secondary.main,
  // },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ForgetPassword = () => {
  const classes = useStyles();

  const [newEmail, setNewEmail] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [showAlert, setShowAlert] = useState({
    msg: "",
    isOpen: false,
    color: "",
  });

  const handelSubmit = async (e) => {
    e.preventDefault();
    try {
      setOpenBackDrop(true);
      await resetPassword(newEmail);
      setShowAlert({
        msg: "Success, Verification Link Sent To The Email",
        isOpen: true,
        color: "success",
      });
    } catch (error) {
      setShowAlert({ msg: error.message, isOpen: true, color: "error" });
    } finally {
      setNewEmail("");
      setOpenBackDrop(false);
    }
  };

  const resetPassword = (email) => auth.sendPasswordResetEmail(email);
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <Backdrop className={classes.backdrop} open={openBackDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={showAlert.isOpen}
          autoHideDuration={6000}
          onClose={() => setShowAlert({ msg: "", isOpen: false, color: "" })}
        >
          <Alert
            onClose={() => setShowAlert({ msg: "", isOpen: false, color: "" })}
            severity={showAlert.color}
          >
            {showAlert.msg}
          </Alert>
        </Snackbar>
        <CssBaseline />
        <div className="paper">
          <Avatar className="avatar">
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Your Password
          </Typography>
          <form className="form" onSubmit={handelSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="forgetSubmit"
            >
              Get Verification Link
            </Button>

            <Button component={Link} to="/">
              Go Back
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ForgetPassword;
