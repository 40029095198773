import { Route, Switch } from "react-router-dom";
import {
  StoreManagerDashboard,
  Support,
  StoreManagerProfile,
  StoreManagerEditProfile,
  StoreManagerInventry,
  ChangePassword,
  Feedback,
  AllUsers,
  StoreManagerAddStoreWorker,
  StoreManagerViewStoreWorker,
  StoreManagerViewSupport,
  StoreManagerNotification,
  StoreManagerViewFeedback,
  StoreManagerEvaluatedCars,
  StoreManagerViewNewBuyer,
  Marketplace,
  StoreManagerBiddingCars,
} from "../Pages";

const StoreManagerRoutes = () => {
  return (
    <Switch>
      <Route path="/" component={StoreManagerDashboard} exact />

      <Route
        path="/store-manager-dashboard"
        component={StoreManagerDashboard}
        exact
      />

      <Route
        path="/add-store-worker"
        component={StoreManagerAddStoreWorker}
        exact
      />
      <Route
        path="/view-store-worker"
        component={StoreManagerViewStoreWorker}
        exact
      />
      <Route
        path="/store-manager-evaluated-cars"
        component={StoreManagerEvaluatedCars}
        exact
      />
      <Route
        path="/store-manager-bidding-cars"
        component={StoreManagerBiddingCars}
        exact
      />
      <Route
        path="/store-manager-inventory"
        component={StoreManagerInventry}
        exact
      />
      <Route
        path="/view-new-buyer"
        component={StoreManagerViewNewBuyer}
        exact
      />

      <Route path="/notification" component={StoreManagerNotification} exact />
      <Route path="/all-users" component={AllUsers} exact />
      <Route
        path="/store-manager-profile"
        component={StoreManagerProfile}
        exact
      />
      <Route
        path="/store-manager-profile/:id"
        component={StoreManagerEditProfile}
        exact
      />
      <Route
        path="/store-manager-edit-profile"
        component={StoreManagerEditProfile}
        exact
      />
      <Route path="/feedback" component={Feedback} exact />
      <Route path="/view-feedback" component={StoreManagerViewFeedback} exact />
      <Route path="/support" component={Support} exact />
      <Route path="/view-support" component={StoreManagerViewSupport} exact />
      <Route path="/change-password" component={ChangePassword} exact />
      <Route path="/Marketplace" component={Marketplace} exact />
    </Switch>
  );
};

export default StoreManagerRoutes;
