import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  Zoom,
  makeStyles,
  useTheme,
} from "@material-ui/core/";
import { ExitToApp } from "@material-ui/icons";
import {
  AccountCircle,
  Assignment,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  DriveEta,
  Feedback,
  GavelRounded,
  HowToReg,
  LiveHelp,
  Menu,
  Notifications,
  PersonAdd,
  Storefront,
  SupervisorAccount,
  ViewComfy,
  ViewModule,
  ViewWeek,
  Visibility,
  VpnKey,
} from "@material-ui/icons/";
import Img from "assets/aditya.jpg";
import clsx from "clsx";
import { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useHistory } from "react-router-dom";
import { useAuthContext } from "./Config";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sidebar: {
    backgroundColor: theme.palette.background.paper,
  },
  labelStyle: {
    textTransform: "capitalize",
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { logout, user, isLoaded } = useAuthContext();

  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => setOpen(false);

  const handleSignOut = async () => {
    try {
      await logout();
      history.push("/");
    } catch (error) {}
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.labelStyle}>
            WELCOME {"\u00A0"} {user?.role}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={Img} alt="Aditya" height="70" />

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider />
        {isLoaded && user?.role === "SUPERADMIN" && (
          <Scrollbars
            style={{ height: "100%" }}
            autoHide
            autoHideTimeout={100}
            autoHideDuration={200}
            autoHeightMin={0}
            renderTrackHorizontal={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  display: "none",
                }}
              />
            )}
          >
            <List component="nav" className={classes.sidebar}>
              <Tooltip
                title="Dashboard"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/super-admin-dashboard">
                  <ListItemIcon>
                    <Dashboard color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Add Stores"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/add-store-admin">
                  <ListItemIcon>
                    <PersonAdd color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Add Stores"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Stores"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-store-admin">
                  <ListItemIcon>
                    <Visibility color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Stores"} />
                </ListItem>
              </Tooltip>

              {/* Spinny Bidding Menu */}
              <Tooltip
                title="Bidding Cars"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/super-admin-bidding-cars"
                >
                  <ListItemIcon>
                    <GavelRounded color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Bidding Cars"} />
                </ListItem>
              </Tooltip>

              {/* Spinny Completed Bidding Menu */}
              <Tooltip
                title="Completed Bidding Cars"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/super-admin-completed-bidding-cars"
                >
                  <ListItemIcon>
                    <HowToReg color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Completed Bidding Cars"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View EvaluatedCar"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/super-admin-view-evaluatedCar"
                >
                  <ListItemIcon>
                    <ViewWeek color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Evaluated Car"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Inventory"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/inventories">
                  <ListItemIcon>
                    <ViewComfy color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Inventory"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Enquiry"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/super-admin-view-enquiry"
                >
                  <ListItemIcon>
                    <ViewModule color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Enquiry"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View MarketPlaceEnquiry"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/market-place-enquiry">
                  <ListItemIcon>
                    <Storefront color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"MarketPlace"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Visitors Database"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/all-users">
                  <ListItemIcon>
                    <SupervisorAccount color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Visitors Database"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Send Notification"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/notification">
                  <ListItemIcon>
                    <Notifications color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Notification"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Support"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-support">
                  <ListItemIcon>
                    <ViewWeek color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Support"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View FeedBack"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/feedback">
                  <ListItemIcon>
                    <Feedback color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Feedback"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Change Password"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/change-password">
                  <ListItemIcon>
                    <VpnKey color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Change Password"} />
                </ListItem>
              </Tooltip>

              <Divider />
              <List>
                <Tooltip
                  title="Logout"
                  TransitionComponent={Zoom}
                  placement="bottom-end"
                  TransitionProps={{ timeout: 600 }}
                >
                  <ListItem button onClick={handleSignOut}>
                    <ListItemIcon>
                      <ExitToApp color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItem>
                </Tooltip>
              </List>
            </List>
          </Scrollbars>
        )}

        {isLoaded && user?.role === "ADMIN" && (
          <Scrollbars
            style={{ height: "100%" }}
            autoHide
            autoHideTimeout={100}
            autoHideDuration={200}
            autoHeightMin={0}
          >
            <List>
              <Tooltip
                title="Dashboard"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/admin-dashboard">
                  <ListItemIcon>
                    <Dashboard color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Add Store Manager"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/add-store-manager">
                  <ListItemIcon>
                    <PersonAdd color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Add Store Manager"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Store Manager"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-store-manager">
                  <ListItemIcon>
                    <Visibility color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Store Manager"} />
                </ListItem>
              </Tooltip>

              {/* <Tooltip
                title="View Car Details"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/AdminCarDetails">
                  <ListItemIcon>
                    <DriveEta color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Car Details"} />
                </ListItem>
              </Tooltip> */}

              <Tooltip
                title="View EvaluatedCar"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/admin-view-evaluated-car"
                >
                  <ListItemIcon>
                    <ViewWeek color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Evaluated Car"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Inventory"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/admin-inventory">
                  <ListItemIcon>
                    <ViewComfy color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Inventory"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Enquiry"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/admin-view-enquiry">
                  <ListItemIcon>
                    <ViewModule color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Enquiry"} />
                </ListItem>
              </Tooltip>

              {/* <Tooltip
                title="View Store Details"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/AdminStoreDetails">
                  <ListItemIcon>
                    <Reorder color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Store Details"} />
                </ListItem>
              </Tooltip> */}

              {/* <Tooltip
                title="Edit Store Details"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/AdminEditStoreDetails">
                  <ListItemIcon>
                    <Edit color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Edit Store"} />
                </ListItem>
              </Tooltip> */}

              <Tooltip
                title="FeedBack"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/feedback">
                  <ListItemIcon>
                    <Feedback color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Feedback"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View FeedBack"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/admin-view-feedback">
                  <ListItemIcon>
                    <Visibility color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View FeedBack"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Visitors Database"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/all-users">
                  <ListItemIcon>
                    <SupervisorAccount color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Visitors Database"} />
                </ListItem>
              </Tooltip>

              {/* Notification Page Exist */}
              {/* <Tooltip
                title="Notification"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/Notification">
                  <ListItemIcon>
                    <Notifications color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Notification"} />
                </ListItem>
              </Tooltip> */}

              <Tooltip
                title="Support"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/support">
                  <ListItemIcon>
                    <LiveHelp color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Support"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Support"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-support">
                  <ListItemIcon>
                    <ViewWeek color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Support"} />
                </ListItem>
              </Tooltip>
              <Tooltip
                title="Change Password"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/change-password">
                  <ListItemIcon>
                    <VpnKey color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Change Password"} />
                </ListItem>
              </Tooltip>

              <Divider />
              <List>
                <Tooltip
                  title="Logout"
                  TransitionComponent={Zoom}
                  placement="bottom-end"
                  TransitionProps={{ timeout: 600 }}
                >
                  <ListItem button onClick={handleSignOut}>
                    <ListItemIcon>
                      <ExitToApp color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItem>
                </Tooltip>
              </List>
            </List>
          </Scrollbars>
        )}

        {isLoaded && user?.role === "STOREMANAGER" && (
          <Scrollbars
            style={{ height: "100%" }}
            autoHide
            autoHideTimeout={100}
            autoHideDuration={200}
            autoHeightMin={0}
          >
            <List>
              <Tooltip
                title="Dashboard"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/store-manager-dashboard">
                  <ListItemIcon>
                    <Dashboard color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Add Store Worker"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/add-store-worker">
                  <ListItemIcon>
                    <PersonAdd color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Add Store Worker"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Store Worker"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-store-worker">
                  <ListItemIcon>
                    <Visibility color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Store Worker"} />
                </ListItem>
              </Tooltip>

              {/* Spinny Bidding Menu */}
              <Tooltip
                title="Bidding Cars"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/store-manager-bidding-cars"
                >
                  <ListItemIcon>
                    <GavelRounded color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Bidding Cars"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Evaluate Cars"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/store-manager-evaluated-cars"
                >
                  <ListItemIcon>
                    <DriveEta color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Evaluated Cars"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Inventory"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/store-manager-inventory">
                  <ListItemIcon>
                    <Assignment color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Inventory"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Enquiry"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-new-buyer">
                  <ListItemIcon>
                    <ViewComfy color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Enquiry"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View FeedBack"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-feedback">
                  <ListItemIcon>
                    <ViewModule color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Feedback"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Visitors Database"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/all-users">
                  <ListItemIcon>
                    <SupervisorAccount color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Visitors Database"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Notification"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/notification">
                  <ListItemIcon>
                    <Notifications color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Notification"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Support"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/support">
                  <ListItemIcon>
                    <LiveHelp color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Support"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Support"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-support">
                  <ListItemIcon>
                    <ViewWeek color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"View Support"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Profile"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/store-manager-profile">
                  <ListItemIcon>
                    <AccountCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Profile"} />
                </ListItem>
              </Tooltip>

              {/* <Tooltip
                title="Edit Profile"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem
                  button
                  component={Link}
                  to="/store-manager-edit-profile"
                >
                  <ListItemIcon>
                    <Edit color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Edit Profile"} />
                </ListItem>
              </Tooltip> */}

              <Tooltip
                title="Update Password"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/change-password">
                  <ListItemIcon>
                    <VpnKey color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Update Password"} />
                </ListItem>
              </Tooltip>
              <Divider />
              <List>
                <Tooltip
                  title="Logout"
                  TransitionComponent={Zoom}
                  placement="bottom-end"
                  TransitionProps={{ timeout: 600 }}
                >
                  <ListItem button onClick={handleSignOut}>
                    <ListItemIcon>
                      <ExitToApp color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItem>
                </Tooltip>
              </List>
            </List>
          </Scrollbars>
        )}

        {isLoaded && user?.role === "STOREWORKER" && (
          <Scrollbars
            style={{ height: "100%" }}
            autoHide
            autoHideTimeout={100}
            autoHideDuration={200}
            autoHeightMin={0}
          >
            <List>
              <Tooltip
                title="Dashboard"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/store-worker-dashboard">
                  <ListItemIcon>
                    <Dashboard color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Evaluate Cars"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/add-evaluate-cars">
                  <ListItemIcon>
                    <Assignment color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Evaluate Cars"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="View Evaluate Cars"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/view-evaluated-cars">
                  <ListItemIcon>
                    <Visibility color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Evaluated Cars"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Support"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/support">
                  <ListItemIcon>
                    <LiveHelp color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Support"} />
                </ListItem>
              </Tooltip>

              <Tooltip
                title="Profile"
                TransitionComponent={Zoom}
                placement="bottom-end"
                TransitionProps={{ timeout: 600 }}
              >
                <ListItem button component={Link} to="/storeWorker-profile">
                  <ListItemIcon>
                    <AccountCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={"Profile"} />
                </ListItem>
              </Tooltip>

              <Divider />
              <List>
                <Tooltip
                  title="Logout"
                  TransitionComponent={Zoom}
                  placement="bottom-end"
                  TransitionProps={{ timeout: 600 }}
                >
                  <ListItem button onClick={handleSignOut}>
                    <ListItemIcon>
                      <ExitToApp color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItem>
                </Tooltip>
              </List>
            </List>
          </Scrollbars>
        )}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default Layout;
