import React from "react";
import { ContextProvider } from "./Config";

import Router from "./Router";
const App = () => {
  return (
    <ContextProvider>
      <Router />
    </ContextProvider>
  );
};

export default App;
