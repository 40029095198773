import { Route, Switch } from "react-router-dom";
import {
  SuperAdminDashboard,
  SuperAdminInventory,
  ChangePassword,
  BookedCar,
  SoldCars,
  AllUsers,
  SuperAdminViewFeedback,
  SuperAdminAddStoreAdmin,
  SuperAdminViewStoreAdmin,
  SuperAdminViewSupport,
  SuperAdminNotification,
  SuperAdminInventoryList,
  SuperAdminViewEvaluatedCar,
  SuperAdminViewEnquiry,
  SuperAdminViewEnquiryList,
  SuperAdminViewEvaluatedCarList,
  SuperAdminMarketPlaceEnquiry,
  Marketplace,
  SuperAdminBiddingCars,
  SuperAdminCompliedBiddingCars,
} from "../Pages";

const SuperAdminRoutes = () => {
  return (
    <Switch>
      <Route path="/" component={SuperAdminDashboard} exact />
      <Route
        path="/super-admin-dashboard"
        component={SuperAdminDashboard}
        exact
      />
      <Route
        path="/add-store-admin"
        component={SuperAdminAddStoreAdmin}
        exact
      />
      <Route
        path="/view-store-admin"
        component={SuperAdminViewStoreAdmin}
        exact
      />

      <Route
        path="/super-admin-view-evaluatedCar"
        component={SuperAdminViewEvaluatedCar}
        exact
      />
      <Route
        path="/SuperAdminViewEvaluatedCar/:id"
        component={SuperAdminViewEvaluatedCarList}
        exact
      />
      <Route path="/inventories" component={SuperAdminInventory} exact />

      <Route
        path="/AddInventory/:id"
        exact
        component={SuperAdminInventoryList}
      />
      <Route
        path="/super-admin-view-enquiry"
        component={SuperAdminViewEnquiry}
        exact
      />
      <Route
        path="/SuperAdminViewEnquiry/:id"
        component={SuperAdminViewEnquiryList}
        exact
      />
      <Route
        path="/super-admin-bidding-cars"
        component={SuperAdminBiddingCars}
        exact
      />
      <Route
        path="/super-admin-completed-bidding-cars"
        component={SuperAdminCompliedBiddingCars}
        exact
      />

      <Route
        path="/market-place-enquiry"
        component={SuperAdminMarketPlaceEnquiry}
        exact
      />

      <Route path="/all-users" component={AllUsers} exact />

      <Route path="/view-support" component={SuperAdminViewSupport} exact />

      <Route path="/BookedCar" component={BookedCar} exact />

      <Route path="/SoldCars" component={SoldCars} exact />

      <Route path="/notification" component={SuperAdminNotification} exact />

      <Route path="/feedback" component={SuperAdminViewFeedback} exact />

      <Route path="/change-password" component={ChangePassword} exact />

      <Route path="/Marketplace" component={Marketplace} exact />
    </Switch>
  );
};

export default SuperAdminRoutes;
