import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { createPortal } from "react-dom";

const PortalAlert = ({ open, msg, handleClose, type }) => {
  return createPortal(
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type || "info"}>
          {msg}
        </Alert>
      </Snackbar>
    </>,
    document.getElementById("portal-alert")
  );
};

export default PortalAlert;
