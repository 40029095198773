const BASE_URL = "https://aditya-next-api.vercel.app/api";
export { BASE_URL };

export function validateStoreName(storeName) {
  const re = /^[a-zA-Z ]+$/;
  return re.test(String(storeName).toLowerCase());
}

export function validatestoreAdminEmail(storeAdminEmail) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(String(storeAdminEmail).toLowerCase());
}
export function validateStoreAdminEmail(storeAdminEmail) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(String(storeAdminEmail).toLowerCase());
}

export function AdminPhoneNumber(storeAdminPhoneNumber) {
  const re = /^\d{5,12}$/;
  return re.test(String(storeAdminPhoneNumber).toLowerCase());
}

export function validatePassword(password) {
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;
  return re.test(password);
}

export function ValidateStoreAdminName(storeAdminName) {
  const re = /^[a-zA-Z ]+$/;
  return re.test(String(storeAdminName).toLowerCase());
}
export function ValidateNumberOfBranches(numberOfBranches) {
  const re = /^[0-9]*$/;
  return re.test(String(numberOfBranches).toLowerCase());
}

export function validateEmail(email) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(String(email).toLowerCase());
}
export function validateStoreManagerName(storeManagerName) {
  const re = /^[a-zA-Z ]+$/;
  return re.test(String(storeManagerName).toLowerCase());
}
export function validateStoreManagerEmail(storeManagerEmail) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(String(storeManagerEmail).toLowerCase());
}
export function validateStoreManagerPhoneNumber(storeManagerPhoneNumber) {
  const re = /^\d{5,12}$/;
  return re.test(String(storeManagerPhoneNumber).toLowerCase());
}
export function validateStoreManagerPassword(password) {
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return re.test(String(password).toLowerCase());
}
export function validateName(Workername) {
  const re = /^[a-zA-Z ]+$/;
  return re.test(String(Workername).toLowerCase());
}
export function validateWorkerEmail(Workeremail) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(String(Workeremail).toLowerCase());
}
export function validateWorkerPhoneNumber(phoneNumber) {
  const re = /^\d{5,12}$/;
  return re.test(String(phoneNumber).toLowerCase());
}
export function validateWorkerPassword(password) {
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return re.test(String(password).toLowerCase());
}
export function validateCustomerName(customerName) {
  const re = /^[a-zA-Z ]+$/;
  return re.test(String(customerName).toLowerCase());
}
export function validateCustomerEmail(customerEmail) {
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return re.test(String(customerEmail).toLowerCase());
}
export function validateCustomerPhoneNumber(customerPhoneNumber) {
  const re =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
  return re.test(String(customerPhoneNumber).toLowerCase());
}
export function validateStoreWorkerPassword(password) {
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return re.test(String(password).toLowerCase());
}

export function validatName(name) {
  const re = /^[a-zA-Z ]+$/;
  return re.test(String(name).toLowerCase());
}

export function validatePhone(phone) {
  const re = /^\d{5,12}$/;
  return re.test(String(phone).toLowerCase());
}
export function validateMultiLine(message) {
  const re = /^([A-Za-z\d\s]){2,1000}$/;
  return re.test(String(message).toLowerCase());
}

export const isCarVehicle = (carVehicle) => {
  const carRegex =
    /^[A-Z]{2}[ -]{0,1}[0-9]{1,2}[ -]{0,1}[A-Z]{1,2}[ -]{0,1}[0-9]{3,4}$/;
  return carVehicle.match(carRegex);
};
export const PurchasePrice = (salesPrice) => {
  const re = /^\d*\.?\d*$/;
  return salesPrice.match(re);
};
export const isSellingPrice = (purchasePrice) => {
  const re = /^\d*\.?\d*$/;
  return purchasePrice.match(re);
};
export const isCheckNumber = (name) => {
  const re = /^[1-9]\d*$/;
  return name.match(re);
};
export const isBuyerName = (purchasePrice) => {
  const re = /^[a-zA-Z ]{2,30}$/;
  return purchasePrice.match(re);
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(amount);
};

export const ImageInfo = (file) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      resolve(image);
    };
  });
};
