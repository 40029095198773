import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const auth = app.auth();
export const database = app.database();
export const storage = app.storage();
export const analytics = app.analytics();

export const UploadImage = ({ dbReference, file }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const imageRef = `${dbReference}/${Date.now()}`;
      const res = await storage.ref(imageRef).put(file);
      const src = await res.ref.getDownloadURL();
      resolve({ imageUrl: src, imageRef: imageRef });
    } catch (error) {
      reject(error);
    }
  });
};

export default app;
