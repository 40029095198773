import PortalAlert from "components/core/PortalAlert";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { auth } from ".";
import app, { database } from "./firebase";

// Create App Context
const AppContext = createContext();

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [uid, setUid] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const logout = () => auth.signOut();
  const resetPassword = (email) => auth.sendPasswordResetEmail(email);
  const isMounted = useRef(false);

  // Login Authentication

  useEffect(() => {
    isMounted.current = true;
    authStateListener();
    return () => (isMounted.current = false);
  }, []);
  const authStateListener = () => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser?.uid) {
        isMounted.current && setUid(currentUser?.uid);
        database.ref(`Users/${currentUser?.uid}/`).on("value", (snap) => {
          if (snap.exists()) {
            const obj = snap.val();
            console.log({ obj });
            if (obj?.storeManagerId) {
              database
                .ref(`Users/${obj?.storeManagerId}/`)
                .once("value", (snap) => {
                  if (snap.exists()) {
                    isMounted.current &&
                      setUser({ ...obj, manager: snap.val() });
                    isMounted.current && setIsLoaded(true);
                  } else {
                    isMounted.current && setUser(obj);
                    isMounted.current && setIsLoaded(true);
                  }
                });
            } else {
              isMounted.current && setUser(obj);
              isMounted.current && setIsLoaded(true);
            }
          }
        });
        "Notification" in window &&
          Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              // Get FCM Token
              app
                .messaging()
                .requestPermission()
                .then(() => {
                  return app.messaging().getToken();
                })
                .then((fcmToken) => {
                  database
                    .ref(`Users/${currentUser?.uid}/fcmTokenWeb`)
                    .set(fcmToken);
                });
            }
          });
      } else {
        isMounted.current && setUser({});
        isMounted.current && setIsLoaded(true);
      }
    });
  };

  // Send Notification
  const sendNotification = async ({ notification, FCMToken }) => {
    const SERVER_KEY =
      "AAAA9WbwY1s:APA91bHLby5xOAoU7tgxTW0wI8bRXpg4Q3g7wQbv0lVmlACEVJIdwHl5mlNz4aqMLZ1oTok0qIk-TslY8DFOk17_7cM6i2VdCeAlskTKOtYhZgg1DWWkCB1Ae6QQ9fzVhsSacHxgc4At";
    const FCM_PUSH_URL = "https://fcm.googleapis.com/fcm/send";

    const body = {
      to: FCMToken,
      data: { message: "hello" },
      notification,
      icon: "icon_name",
      priority: "high",
    };

    try {
      const response = await fetch(FCM_PUSH_URL, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "key=" + SERVER_KEY,
        },
      });

      const results = await response?.json();
      return results;
    } catch (error) {}
  };

  const [snack, setSnack] = useState({
    msg: "",
    open: false,
  });
  return (
    <AppContext.Provider
      value={{
        isLoaded,
        user,
        uid,
        logout,
        resetPassword,
        sendNotification,
        snack,
        setSnack,
      }}
    >
      <PortalAlert
        handleClose={() => setSnack({ msg: "", open: false })}
        {...snack}
      />
      {children}
    </AppContext.Provider>
  );
};

export const useAuthContext = () => {
  const {
    isLoaded,
    user,
    uid,
    logout,
    resetPassword,
    sendNotification,
    snack,
    setSnack,
  } = useContext(AppContext);
  return {
    isLoaded,
    user,
    uid,
    logout,
    resetPassword,
    sendNotification,
    snack,
    setSnack,
  };
};
