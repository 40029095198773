import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  Grow,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Email, Lock, Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { auth } from "Config";
import { validateEmail, validatePassword } from "Helpers";
import { LOGO } from "assets";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [isEmailChange, setIsEmailChange] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState({
    msg: "",
    isOpen: false,
    color: "",
    severity: "info",
  });

  const handelSubmit = async (e) => {
    e.preventDefault();
    try {
      setOpenBackDrop(true);
      await auth?.signInWithEmailAndPassword(email, password);
      history.push("/");
      setShowAlert({
        msg: "Successfully Login",
        isOpen: true,
        color: "success",
        severity: "info",
      });
    } catch (error) {
      console.log({ error });
      setShowAlert({
        msg: "Please Enter Valid Email And Password",
        isOpen: true,
        color: "error",
        severity: "info",
      });
    } finally {
      setOpenBackDrop(false);
    }
  };

  return (
    <div>
      <Container component="main" maxWidth="xs">
        <Snackbar
          open={showAlert.isOpen}
          autoHideDuration={6000}
          onClose={() => setShowAlert({ msg: "", isOpen: false, color: "" })}
        >
          <Alert
            onClose={() => setShowAlert({ msg: "", isOpen: false, color: "" })}
            severity={showAlert.color}
          >
            {showAlert.msg}
          </Alert>
        </Snackbar>
        <CssBaseline />
        <div className={classes.paper}>
          <Card>
            <CardContent>
              <div className="logo_container">
                <img alt="" className="logo_style" src={LOGO} />
              </div>

              <Typography component="h1" variant="h5" align="center">
                Login
              </Typography>
              <form className="form" onSubmit={handelSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  InputProps={{
                    startAdornment: <Email />,
                    style: {
                      borderColor: "red",
                    },
                  }}
                  error={isEmailChange && !validateEmail(email)}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setIsEmailChange(true);
                  }}
                />
                <Grow
                  in={isEmailChange && !validateEmail(email)}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(isEmailChange && !validateEmail(email)
                    ? { timeout: 1000 }
                    : {})}
                >
                  <label style={{ color: "red" }}>
                    Please Enter a Email Id
                  </label>
                </Grow>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  name="password"
                  id="password"
                  autoComplete="email"
                  autoFocus
                  InputProps={{
                    startAdornment: <Lock />,
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                    style: {
                      borderColor: "red",
                    },
                  }}
                  error={isEmailChange && !validateEmail(email)}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setIsPasswordChange(true);
                  }}
                />
                <Grow
                  in={
                    isPasswordChange && !password && !validatePassword(password)
                  }
                  style={{ transformOrigin: "0 0 0" }}
                  {...(isPasswordChange &&
                  !password &&
                  !validatePassword(password)
                    ? { timeout: 1000 }
                    : {})}
                >
                  <label style={{ color: "red" }}>
                    Please Enter a Password
                  </label>
                </Grow>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="submit"
                  disabled={openBackDrop}
                >
                  {openBackDrop && (
                    <CircularProgress
                      color="inherit"
                      size={15}
                      style={{ margin: "0 1vw" }}
                    />
                  )}
                  Sign In
                </Button>
                <Backdrop className={classes.backdrop} open={openBackDrop}>
                  <CircularProgress color="primary" />
                </Backdrop>
                <Button component={Link} to="/ForgetPassword">
                  Forgot password?
                </Button>
              </form>
            </CardContent>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Login;
