import { Route, Switch } from "react-router-dom";
import {
  StoreWorkerDashboard,
  Support,
  StoreWorkerProfile,
  StoreWorkerEditProfile,
  StoreWorkerViewEvaluatedCars,
  StoreWorkerAddEvaluateCars,
  StoreWorkerNewBuyer,
  // StoreWorkerViewNewBuyer,
  StoreWorkerViewInventory,
  StoreWorkerViewNewBuyer,
  Marketplace,
} from "../Pages";

const StoreWorkerRoutes = () => {
  return (
    <Switch>
      <Route path="/" component={StoreWorkerDashboard} exact />
      <Route
        path="/store-worker-dashboard"
        component={StoreWorkerDashboard}
        exact
      />

      <Route
        path="/add-evaluate-cars"
        component={StoreWorkerAddEvaluateCars}
        exact
      />

      <Route
        path="/view-evaluated-cars"
        component={StoreWorkerViewEvaluatedCars}
        exact
      />
      <Route path="/new-enquiry" component={StoreWorkerNewBuyer} exact />
      <Route path="/view-enquiry" component={StoreWorkerViewNewBuyer} exact />
      <Route
        path="/view-inventory"
        component={StoreWorkerViewInventory}
        exact
      />
      <Route path="/storeWorker-profile" component={StoreWorkerProfile} exact />
      <Route
        path="/storeWorker-profile/:id/"
        component={StoreWorkerEditProfile}
        exact
      />
      <Route
        path="/storeWorker-edit-profile"
        component={StoreWorkerEditProfile}
        exact
      />
      <Route path="/support" component={Support} exact />
      {/* <Route path="/ViewSupport" component={ViewSupport} exact /> */}

      <Route path="/Marketplace" component={Marketplace} exact />
    </Switch>
  );
};

export default StoreWorkerRoutes;
