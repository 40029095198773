import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Send, Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useAuthContext } from "Config";
import { database } from "Config/firebase";
import { STATES } from "data";
import {
  BASE_URL,
  validateStoreManagerEmail,
  validateStoreManagerName,
  validateStoreManagerPassword,
  validateStoreManagerPhoneNumber,
} from "Helpers";
import useCountry from "Hooks/useCountry";
import Layout from "Layout";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const AdminAddStoreManager = () => {
  const classes = useStyles();
  const { countryList } = useCountry();
  const { user } = useAuthContext();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Odisha");
  const [country, setCountry] = useState({
    code: "IN",
    name: "India",
  });

  const [showAlert, setShowAlert] = useState({
    msg: "",
    isOpen: false,
    color: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [isStoreManagerName, setIsStoreManagerName] = useState(false);
  const [isStoreManagerEmail, setIsStoreManagerEmail] = useState(false);
  const [isStoreManagerPhoneNumber, setIsStoreManagerPhoneNumber] =
    useState(false);
  const [isStoreManagerPassword, setIsStoreManagerPassword] = useState(false);

  const handelSubmit = async (e) => {
    e.preventDefault();
    const role = "STOREMANAGER";
    setOpenBackDrop(true);
    try {
      const res = await fetch(`${BASE_URL}/create-user`, {
        method: "POST",
        // Adding headers to the request
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          displayName: name,
        }),
      });
      const data = await res.json();
      if (!data?.uid) throw new Error(data?.error?.message);
      const storeId = user?.id;
      const id = data?.uid;
      await database.ref(`Users/${id}/`).set({
        storeId,
        id,
        name,
        email,
        phoneNumber,
        password,
        city,
        country,
        role,
        state,
        timestamp: new Date(),
      });
      setShowAlert({
        msg: "Store Manager Created Successfully",
        isOpen: true,
        color: "success",
      });
    } catch (error) {
      setShowAlert({
        msg: error.message,
        isOpen: true,
        color: "error",
      });
    } finally {
      setOpenBackDrop(false);
      setIsStoreManagerName(false);
      setIsStoreManagerEmail(false);
      setIsStoreManagerPhoneNumber(false);
      setIsStoreManagerPassword(false);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setPassword("");
      setCity("");
      setState("");
      setCountry("");
    }
  };

  return (
    <Layout>
      <Container component="main" maxWidth="md">
        <Backdrop className={classes.backdrop} open={openBackDrop}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar
          open={showAlert.isOpen}
          autoHideDuration={6000}
          onClose={() => setShowAlert({ msg: "", isOpen: false, color: "" })}
        >
          <Alert
            onClose={() => setShowAlert({ msg: "", isOpen: false, color: "" })}
            severity={showAlert.color}
          >
            {showAlert.msg}
          </Alert>
        </Snackbar>
        <CssBaseline />
        <div className="AddStoreManagerPaper">
          <form className="form" onSubmit={handelSubmit}>
            <Card className="card">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={
                        isStoreManagerName && !validateStoreManagerName(name)
                      }
                      id="storeManagerName"
                      label="Store Manager Name"
                      name="StoreManagerName"
                      autoComplete="Store Manager Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setIsStoreManagerName(true);
                      }}
                    />
                    <Grow
                      in={isStoreManagerName && !validateStoreManagerName(name)}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(isStoreManagerName && !validateStoreManagerName(name)
                        ? { timeout: 1000 }
                        : {})}
                    >
                      <label style={{ color: "red" }}>
                        Please Enter Your Store Manager Name
                      </label>
                    </Grow>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      error={
                        isStoreManagerEmail && !validateStoreManagerEmail(email)
                      }
                      type="email"
                      id="storeManagerEmail"
                      label="Store Manager Email"
                      name="Store Manager Email"
                      autoComplete="Store Manager Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setIsStoreManagerEmail(true);
                      }}
                    />
                    <Grow
                      in={
                        isStoreManagerEmail && !validateStoreManagerEmail(email)
                      }
                      style={{ transformOrigin: "0 0 0" }}
                      {...(isStoreManagerEmail &&
                      !validateStoreManagerEmail(email)
                        ? { timeout: 1000 }
                        : {})}
                    >
                      <label style={{ color: "red" }}>
                        Please Enter Your Store Manager Email
                      </label>
                    </Grow>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      type="number"
                      fullWidth
                      error={
                        isStoreManagerPhoneNumber &&
                        !validateStoreManagerPhoneNumber(phoneNumber)
                      }
                      id="storeManagerPhoneNumber"
                      label="Store Manager Phone Number"
                      name="Store Manager PhoneNumber"
                      autoComplete="Store Manager PhoneNumber"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        setIsStoreManagerPhoneNumber(true);
                      }}
                    />
                    <Grow
                      in={
                        isStoreManagerPhoneNumber &&
                        !validateStoreManagerPhoneNumber(phoneNumber)
                      }
                      style={{ transformOrigin: "0 0 0" }}
                      {...(isStoreManagerPhoneNumber &&
                      !validateStoreManagerPhoneNumber(phoneNumber)
                        ? { timeout: 1000 }
                        : {})}
                    >
                      <label style={{ color: "red" }}>
                        Please Enter Your Store Manager Phone Number
                      </label>
                    </Grow>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <OutlinedInput
                      variant="outlined"
                      required
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      fullWidth
                      error={
                        isStoreManagerPassword &&
                        !validateStoreManagerPassword(password)
                      }
                      id="storeManagerPassword"
                      name="Store Manager Password"
                      autoComplete="Store Manager Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setIsStoreManagerPassword(true);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Grow
                      in={
                        isStoreManagerPassword &&
                        !validateStoreManagerPassword(password)
                      }
                      style={{ transformOrigin: "0 0 0" }}
                      {...(isStoreManagerPassword &&
                      !validateStoreManagerPassword(password)
                        ? { timeout: 1000 }
                        : {})}
                    >
                      <label style={{ color: "red" }}>
                        Password at least one number one special character
                      </label>
                    </Grow>
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="city"
                      label="City"
                      name="city"
                      autoComplete="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <>
                      <Autocomplete
                        options={STATES}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          setState(value?.name || "");
                        }}
                        value={{ name: state }}
                        autoHighlight
                        fullWidth
                        disablePortal={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select State"
                            variant="outlined"
                            id="state"
                            name="state"
                            required
                          />
                        )}
                      />
                    </>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div style={{ marginTop: "8px" }}>
                      <Autocomplete
                        options={countryList}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => setCountry(value)}
                        value={country}
                        autoHighlight
                        fullWidth
                        disablePortal={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Country"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </div>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <div className="buttonParent">
                      <Button
                        endIcon={<Send />}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </div>
      </Container>
    </Layout>
  );
};

export default AdminAddStoreManager;
