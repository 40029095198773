export const STATES = [
  { name: "Andaman and Nicobar Islands", id: "1" },
  { name: "Andhra Pradesh", id: "2" },
  { name: "Arunachal Pradesh", id: "3" },
  { name: "Assam", id: "4" },
  { name: "Bihar", id: "5" },
  { name: "Chandigarh", id: "6" },
  { name: "Chhattisgarh", id: "7" },
  { name: "Dadra and Nagar Haveli", id: "8" },
  { name: "Daman and Diu", id: "9" },
  { name: "Delhi", id: "10" },
  { name: "Goa", id: "11" },
  { name: "Gujarat", id: "12" },
  { name: "Haryana", id: "13" },
  { name: "Himachal Pradesh", id: "14" },
  { name: "Jammu and Kashmir", id: "15" },
  { name: "Jharkhand", id: "16" },
  { name: "Karnataka", id: "17" },
  { name: "Kerala", id: "18" },
  { name: "Ladakh", id: "19" },
  { name: "Lakshadweep", id: "20" },
  { name: "Madhya Pradesh", id: "21" },
  { name: "Maharashtra", id: "22" },
  { name: "Manipur", id: "23" },
  { name: "Meghalaya", id: "24" },
  { name: "Mizoram", id: "25" },
  { name: "Nagaland", id: "26" },
  { name: "Odisha", id: "27" },
  { name: "Puducherry", id: "28" },
  { name: "Punjab", id: "29" },
  { name: "Rajasthan", id: "30" },
  { name: "Sikkim", id: "31" },
  { name: "Tamil Nadu", id: "32" },
  { name: "Telangana", id: "33" },
  { name: "Tripura", id: "34" },
  { name: "Uttar Pradesh", id: "35" },
  { name: "Uttarakhand", id: "36" },
  { name: "West Bengal", id: "37" },
];

export const HUB_LOCATIONS = [
  "BHUBANESWAR",
  "NAKHARA",
  "TAMANDO",
  "UTTARA",
  "CUTTACK",
  "PANIKOILI",
];
